import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  BodyDeleteDocumentExpediente,
  InputItemExpedienteFileCar,
  ItemCatalogueExpedienteCar,
  UpdateItemExpedienteFileCar,
} from '@expediente/models/expediente-car.model';
import { ResponseBackAzure } from '@core/models/responsehttp.model';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';

import JSZip from 'jszip';
import FileSaver from 'file-saver';
import JSZipUtils from 'jszip-utils';
import { getExtensionFile } from '@shared/index';

@Injectable({
  providedIn: 'root',
})
export class ExpedienteService {
  private readonly _http = inject(HttpClient);
  httpParam = new HttpParams();

  private readonly initFilter = {
    tipo_operacion: undefined,
    tipo_transaccion: undefined,
    id: undefined,
    etapa: undefined,
  };
  constructor() {
    this.httpParam = this.httpParam.append('backend', 'azure');
  }

  getCatalogue(filtros?: { tipo?: string; origen?: string; etapa?: string }) {
    const queryParams = `tipo=${filtros?.tipo ?? ''}&origen=${filtros?.origen ?? ''}&etapa=${filtros?.etapa ?? ''}`;

    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos/catalogo?${queryParams}`,
      {
        params: this.httpParam,
      }
    );
  }

  postCatalogue(catalogo: ItemCatalogueExpedienteCar) {
    return this._http.post<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos/catalogo`,
      catalogo,
      {
        params: this.httpParam,
      }
    );
  }

  getDocumentsByCarNCitaNdocumentId(autoId?: string, documentoId?: string, id_cita?: string) {
    const queryParams = `id_automovil=${autoId ?? ''}&id_documento=${documentoId ?? ''}&id_cita=${id_cita ?? ''}`;

    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos?${queryParams}`,
      {
        params: this.httpParam,
      }
    );
  }

  postDocumentByCar(file: InputItemExpedienteFileCar) {
    return this._http.post<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos`, file, {
      params: this.httpParam,
    });
  }

  putDocumentByCar(file: UpdateItemExpedienteFileCar) {
    return this._http.put<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos`, file, {
      params: this.httpParam,
    });
  }

  deleteDocument(list_url: string[]) {
    return this._http.delete<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/v2/web/storage/loadimage`, {
      body: { list_url },
      params: this.httpParam,
    });
  }

  deleteDocumentV2(body: BodyDeleteDocumentExpediente) {
    return this._http.delete<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos`, {
      body,
      params: this.httpParam,
    });
  }

  getImagenesAutoExistentesOrdenadas(idAutomovil: string) {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/mesacontrol/imagenes/${idAutomovil}?backend=azure-crm`);
  }

  validacionFotos(body) {
    return this._http.post(`${environment.API_URL_MANAGEMENT}/mesacontrol/imagenes/validacion?backend=azure-crm`, body);
  }

  convertToBase64(url: string): Observable<string> {
    const result = new ReplaySubject<string>(1);
    this._http.get(url, { responseType: 'blob' }).subscribe((blob) => {
      const reader = new FileReader();
      reader.readAsBinaryString(blob);
      reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    });
    return result;
  }

  generateZipDownload = async (fileNameZip: string, documents: ItemCatalogueExpedienteCar[]) => {
    const docs = documents.filter((doc) => doc.fileSaved && doc.fileSaved.length > 0);

    if (docs.length === 0) return;

    const urlToPromise = (url) => {
      return new Promise((resolve, reject) => {
        JSZipUtils.getBinaryContent(url, (err, data) => {
          if (err) reject(err);
          else resolve(data);
        });
      });
    };

    const zip = new JSZip();

    for (const doc of docs) {
      for (const urlFile of doc.fileSaved) {
        const binary = (await urlToPromise(urlFile.url)) as string | Blob | ArrayBuffer | number[] | Uint8Array;
        const filename = `${doc.categoria ?? 'OTROS'}/${decodeURIComponent(doc.nombre)}.${getExtensionFile(urlFile.url)}`;
        zip.file(filename, binary, { binary: true });
      }
    }

    await zip.generateAsync({ type: 'blob' }).then((blob) => {
      FileSaver.saveAs(blob, `${fileNameZip}.zip`);
    });
  };
}
